import { HEADER_HEIGHT } from "@src/utils/constants/sizes";
import { Heading, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import type { ViewStyle } from "react-native";

export const OpportunitiesHeader = () => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly headerItem: ViewStyle;
  }>({
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      flexDirection: "row",
      height: HEADER_HEIGHT,
      justifyContent: "space-between",
    },
    headerItem: {
      flex: 1,
      paddingHorizontal: theme.spacing.xxl,
    },
  });

  const { _ } = useLingui();

  return (
    <View style={styles.container}>
      <View style={styles.headerItem}>
        <Heading level="1" numberOfLines={1}>
          <Trans>Opportunities</Trans>
        </Heading>
      </View>
    </View>
  );
};

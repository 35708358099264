/* eslint-disable lingui/no-unlocalized-strings */
import type { JobPosting } from "./types";

export const mockJobPostings: readonly JobPosting[] = [
  {
    activeDays: 25,
    company: "Apollo Electric",
    description:
      "Minimum of 1 year experience working on residential/commercial electrical,Skilled at terminating and pulling wires into control centers",
    experienceLevel: "1+ years experience",
    id: "550e8400-e29b-41d4-a716-446655440000",
    isMeritPending: true,
    jobType: "Full-time",
    location: "New York, NY 10001",
    opportunityType: "JobPosting",
    postedAt: "2023-03-11",
    postingURL: "https://example.com/jobs/123",
    rating: 3.5,
    salary: "$18 - $35/hour",
    salaryType: "Hourly",
    setting: "On-site",
    shiftAndSchedule: "Day shift",
    title: "Electrical Technician",
  },
  {
    company: "Top Shelf Electrical Corp.",
    description:
      "Experience in new construction environment, High-rise electrical installation experience",
    experienceLevel: "3+ years experience",
    id: "550e8400-e29b-41d4-a716-446655440001",
    jobType: "Full-time",
    location: "New York, NY 10001",
    opportunityType: "JobPosting",
    postedAt: "2023-03-01",
    postingURL: "https://example.com/jobs/456",
    rating: 2.4,
    salary: "$16 - $60/hour",
    salaryType: "Hourly",
    shiftAndSchedule: "8 hour shift",
    title: "Electrical Foreman",
  },
  {
    activeDays: 4,
    company: "EEC Corporation",
    description: "Own transportation and tools required,PPE certification",
    experienceLevel: "5+ years experience",
    id: "550e8400-e29b-41d4-a716-446655440002",
    isMeritPending: true,
    jobType: "Full-time",
    location: "New York, NY",
    opportunityType: "JobPosting",
    postedAt: "2023-03-15",
    postingURL: "https://example.com/jobs/789",
    salary: "$30 - $45/hour",
    salaryType: "Hourly",
    shiftAndSchedule: "40 hours/week, Monday-Friday",
    title: "Electrical Mechanic - FOREMAN",
  },
];

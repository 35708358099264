import { EntityInfoTypeEnum } from "@merit/issuance-client";
import { Icon } from "@merit/frontend-components";
import { Image, StyleSheet } from "react-native";
import { Text } from "@src/utils/constants";
import { useConfig } from "@src/hooks";
import { useState } from "react";
import MeritLogo from "@src/assets/images/merit-logotype.png";
import OhioAceLogo from "@src/assets/images/ohio-ace-logo.png";
import type { FC } from "react";
import type { ImageStyle } from "react-native";
import type { Merit } from "@src/api/issuance";

export type OrgLogoProps = {
  readonly merit?: Merit;
};

export const OrgLogo: FC<OrgLogoProps> = ({ merit }) => {
  const styles = StyleSheet.create<{
    readonly defaultLogoOverlay: ImageStyle;
    readonly logo: ImageStyle;
  }>({
    defaultLogoOverlay: {
      height: "100%",
      position: "absolute",
      width: "100%",
    },
    logo: {
      height: "100%",
      width: "100%",
    },
  });

  const config = useConfig();
  const [isRemoteImageLoaded, setIsRemoteImageLoaded] = useState(false);
  const [hasErrorLoadingImage, setHasErrorLoadingImage] = useState(false);

  if (merit === undefined) {
    return <Icon name="defaultOrg" style={styles.logo} />;
  }

  /*
    TODO: we really should match against org ids, and we get a list via remote configs or similar
    at a minimum, the Merit full and short company names should be migrated to matching against Merit's org ID
  */
  const orgName = merit.transformedFields.orgName;
  if (orgName === Text.MERIT_COMPANY_NAME || orgName === Text.MERIT_COMPANY_SHORT_NAME) {
    return <Image resizeMode="contain" source={MeritLogo} style={styles.logo} />;
  }
  if (orgName === "Ohio ACE") {
    return <Image resizeMode="contain" source={OhioAceLogo} style={styles.logo} />;
  }

  const { id, type } = merit.issuer ?? {};
  const orgLogoUrl =
    type === EntityInfoTypeEnum.Organization &&
    id !== undefined &&
    config.externalResourcesConfig.orgLogosBaseUrl !== ""
      ? `${config.externalResourcesConfig.orgLogosBaseUrl}${config.externalResourcesConfig.orgLogoFilePrefix}${id}`
      : undefined;

  return (
    <>
      <Image
        onError={() => {
          setHasErrorLoadingImage(true);
        }}
        onLoad={() => {
          setIsRemoteImageLoaded(true);
        }}
        resizeMode="contain"
        source={hasErrorLoadingImage ? undefined : { uri: orgLogoUrl }}
        style={styles.logo}
      />
      {
        // need to overlay the default image instead of defaultSource prop, since the latter
        // does not work consistently on Android. We also want the remote image component to
        // be visible (e.g. not display: hidden, has positive dimensions) so iOS would actually
        // initiate the image fetching, otherwise onLoad will not fire on iOS and thus, no logo
        !isRemoteImageLoaded && <Icon name="defaultOrg" style={styles.defaultLogoOverlay} />
      }
    </>
  );
};

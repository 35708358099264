import { Body, Heading, LineSeparator, useTheme } from "@merit/frontend-components";
import { FlatList, StyleSheet, View } from "react-native";
import { Helpers } from "@merit/frontend-utils";
import { Log } from "@src/utils";
import { MeritListItemSkeleton } from "@src/components";
import { SharedLinkDeleteConfirmationModal } from "./SharedLinkDeleteConfirmationModal";
import { SharedLinksListItem } from "./SharedLinksListItem.desktop";
import { Trans } from "@lingui/macro";
import { range } from "lodash";
import { useDeleteSharedLink, useGetShares } from "@src/api/share-merits";
import { useGetTestProps } from "@src/hooks";
import React, { useCallback, useMemo, useState } from "react";
import type { ViewStyle } from "react-native";

const { None, Some } = Helpers;

export const SharedLinksList = () => {
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();
  const {
    data: sharedMerits,
    error,
    fetchNextPage,
    isError,
    isFetchingNextPage,
    isLoading,
  } = useGetShares();
  const { mutate } = useDeleteSharedLink();

  const [shareIdToDelete, setShareIdToDelete] = useState<string | undefined>();

  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly headerTextContainer: ViewStyle;
    readonly flex: ViewStyle;
    readonly horizontalMargin: ViewStyle;
    readonly emptyStateContainer: ViewStyle;
  }>({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
    emptyStateContainer: {
      alignItems: "center",
      padding: theme.spacing.l,
    },
    flex: { alignItems: "center", flex: 1, justifyContent: "center" },
    headerTextContainer: {
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.l,
    },
    horizontalMargin: {
      marginLeft: theme.spacing.xxl,
      marginRight: theme.spacing.xxl,
    },
  });

  const handleDelete = useCallback(() => {
    if (None(shareIdToDelete)) {
      return;
    }

    mutate(shareIdToDelete);
    setShareIdToDelete(undefined);
  }, [mutate, shareIdToDelete]);

  const shares = useMemo(() => {
    if (None(sharedMerits)) {
      return [];
    }

    return sharedMerits.pages.flatMap(page => page.shares ?? []);
  }, [sharedMerits]);

  if (isError) {
    Log.error(`Error loading shareMerits: ${String(error)}`);

    return (
      <View style={[styles.flex, styles.container]}>
        <Heading level="4" style={styles.horizontalMargin}>
          <Trans>Error loading your shared links</Trans>
        </Heading>
      </View>
    );
  }

  if (isLoading || sharedMerits === undefined) {
    return (
      <View
        style={styles.container}
        {...getTestProps({
          elementName: "SharedLinks",
        })}
      >
        <FlatList
          ItemSeparatorComponent={() => <LineSeparator />}
          ListHeaderComponent={() => (
            <>
              <LineSeparator />
              <View style={styles.headerTextContainer}>
                <Body>
                  <Trans context="sort">Most recent</Trans>
                </Body>
              </View>
              <LineSeparator />
            </>
          )}
          data={range(5)}
          renderItem={({ item }) => <MeritListItemSkeleton key={item} />}
          {...getTestProps({
            elementId: "merits",
            elementName: "SharedLinks",
          })}
        />
      </View>
    );
  }

  return (
    <View
      style={styles.container}
      {...getTestProps({
        elementName: "SharedLinks",
      })}
    >
      <FlatList
        ItemSeparatorComponent={() => <LineSeparator />}
        ListHeaderComponent={() => (
          <>
            <LineSeparator />
            <View style={styles.headerTextContainer}>
              <Body>
                <Trans context="sort">Most recent</Trans>
              </Body>
            </View>
            <LineSeparator />
          </>
        )}
        data={shares}
        renderItem={({ item }) => (
          <SharedLinksListItem onPressDelete={setShareIdToDelete} shareMerit={item} />
        )}
        {...getTestProps({
          elementId: "merits",
          elementName: "SharedLinks",
        })}
        ListEmptyComponent={
          <View style={styles.emptyStateContainer}>
            <Body>
              <Trans>You have no active share links.</Trans>
            </Body>
          </View>
        }
        ListFooterComponent={isFetchingNextPage ? <MeritListItemSkeleton /> : null}
        onEndReached={() => {
          if (!isFetchingNextPage) {
            fetchNextPage();
          }
        }}
      />
      {Some(shareIdToDelete) && (
        <SharedLinkDeleteConfirmationModal
          onClose={() => {
            setShareIdToDelete(undefined);
          }}
          onPressDelete={handleDelete}
        />
      )}
    </View>
  );
};

import { Body, LineSeparator, useTheme } from "@merit/frontend-components";
import { FlatList, RefreshControl, StyleSheet, View } from "react-native";
import { Helpers } from "@merit/frontend-utils";
import { Log } from "@src/utils";
import { MeritListItemSkeleton } from "@src/components";
import { SharedLinkDeleteConfirmationModal } from "./SharedLinkDeleteConfirmationModal";
import { SharedLinksListItem } from "./SharedLinksListItem.mobile";
import { Skeleton } from "../MeritsScreen/Skeleton";
import { Trans } from "@lingui/macro";
import { range } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useDeleteSharedLink, useGetShares } from "@src/api/share-merits";
import { useGetTestProps } from "@src/hooks";
import type { ViewStyle } from "react-native";

const { None, Some } = Helpers;

export const SharedLinksList = () => {
  const { theme } = useTheme();
  const {
    data: sharedMerits,
    error,
    fetchNextPage,
    isError,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useGetShares();
  const { mutate } = useDeleteSharedLink();

  const [shareIdToDelete, setShareIdToDelete] = useState<string | undefined>();

  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly flex: ViewStyle;
    readonly horizontalMargin: ViewStyle;
    readonly bottomMargin: ViewStyle;
    readonly emptyStateContainer: ViewStyle;
  }>({
    bottomMargin: {
      marginBottom: theme.spacing.l,
    },
    container: {
      backgroundColor: theme.colors.background.white,
      paddingTop: theme.spacing.l,
    },
    emptyStateContainer: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
    },
    flex: { flex: 1 },
    horizontalMargin: {
      marginLeft: theme.spacing.xxl,
      marginRight: theme.spacing.xxl,
    },
  });

  const getTestProps = useGetTestProps();

  const handleDelete = useCallback(() => {
    if (None(shareIdToDelete)) {
      return;
    }

    mutate(shareIdToDelete);
    setShareIdToDelete(undefined);
  }, [mutate, shareIdToDelete]);

  const shares = useMemo(() => {
    if (None(sharedMerits)) {
      return [];
    }

    return sharedMerits.pages.flatMap(page => page.shares ?? []);
  }, [sharedMerits]);

  if (isError) {
    Log.error(`Error loading share merits: ${String(error)}`);

    return (
      <View style={[styles.flex, styles.container]}>
        <Body style={styles.horizontalMargin}>
          <Trans>Error loading your shared links</Trans>
        </Body>
      </View>
    );
  }

  if (isLoading || sharedMerits === undefined) {
    return (
      <View style={[styles.flex, styles.container]}>
        {range(5).map((item, index) => (
          <Skeleton key={item} showTopSeparator={index !== 0} />
        ))}
      </View>
    );
  }

  return (
    <View
      style={[styles.flex, styles.container]}
      {...getTestProps({
        elementName: "SharedLinks",
      })}
    >
      <LineSeparator style={styles.bottomMargin} />
      <View style={[styles.bottomMargin, styles.horizontalMargin]}>
        <Body>
          <Trans context="sort">Most recent</Trans>
        </Body>
      </View>
      <LineSeparator style={styles.bottomMargin} />
      <View style={[styles.horizontalMargin, styles.flex]}>
        <FlatList
          ItemSeparatorComponent={() => <LineSeparator />}
          data={shares}
          refreshControl={
            <RefreshControl
              onRefresh={() => {
                refetch();
              }}
              refreshing={false}
            />
          }
          renderItem={({ item }) => (
            <SharedLinksListItem onPressDelete={setShareIdToDelete} shareMerit={item} />
          )}
          {...getTestProps({
            elementId: "sharedLink",
            elementName: "SharedLinks",
          })}
          ListEmptyComponent={
            <View style={styles.emptyStateContainer}>
              <Body>
                <Trans>You have no active share links.</Trans>
              </Body>
            </View>
          }
          ListFooterComponent={isFetchingNextPage ? <MeritListItemSkeleton /> : null}
          onEndReached={() => {
            if (!isFetchingNextPage) {
              fetchNextPage();
            }
          }}
        />
      </View>
      {Some(shareIdToDelete) && (
        <SharedLinkDeleteConfirmationModal
          onClose={() => {
            setShareIdToDelete(undefined);
          }}
          onPressDelete={handleDelete}
        />
      )}
    </View>
  );
};

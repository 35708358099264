import { HEADER_HEIGHT } from "@src/utils/constants/sizes";
import { Heading, TextInput, useTheme } from "@merit/frontend-components";
import { PersonalQrCodeButton } from "@src/components";
import { StyleSheet, View } from "react-native";
import { msg } from "@lingui/macro";
import { useGetTestProps } from "@src/hooks";
import { useLingui } from "@lingui/react";
import type { PropsWithChildren } from "react";
import type { ViewStyle } from "react-native";

export type SideBarContentHeaderProps = {
  readonly title: string;
  readonly onChangeText?: (text: string) => Promise<void> | void;
  readonly onFocus?: () => Promise<void> | void;
};

export const SideBarContentHeader = ({
  onChangeText,
  onFocus,
  title,
}: PropsWithChildren<SideBarContentHeaderProps>) => {
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();
  const { _ } = useLingui();

  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly headerItem: ViewStyle;
    readonly personalQrCodeButtonWrapper: ViewStyle;
  }>({
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      flexDirection: "row",
      height: HEADER_HEIGHT,
      justifyContent: "space-between",
    },
    headerItem: {
      flex: 1,
      paddingHorizontal: theme.spacing.xxl,
    },
    personalQrCodeButtonWrapper: {
      alignSelf: "flex-end",
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.headerItem}>
        <Heading
          level="1"
          numberOfLines={1}
          {...getTestProps({
            elementId: "heading",
            elementName: "SideBarContentHeader",
          })}
        >
          {title}
        </Heading>
      </View>
      <View style={styles.headerItem}>
        <TextInput
          leftIcon="searchSmallSubdued"
          onChangeText={onChangeText}
          onFocus={onFocus}
          placeholder={_(msg({ context: "placeholder", message: "Search" }))}
          {...getTestProps({
            elementId: "searchInput",
            elementName: "SideBarContentHeader",
          })}
        />
      </View>
      <View style={styles.headerItem}>
        <PersonalQrCodeButton wrapperStyle={styles.personalQrCodeButtonWrapper} />
      </View>
    </View>
  );
};

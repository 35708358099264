import { Constants, getChosenNameFromContainerFields, openExternalLink } from "@src/utils";
import { Home, MeritsScreen, OpportunitiesScreen, Settings, SharedLinksScreen } from "@src/screens";
import { Icon } from "@merit/frontend-components";
import { createSideBarNavigator } from "@src/components/SideBarNavigator";
import { msg } from "@lingui/macro";
import { useAccountMerit } from "@src/api/issuance";
import { useFeatureFlags, useLogout, useMeritAuth0 } from "@src/hooks";
import { useLingui } from "@lingui/react";
import React from "react";
import type { ScreenParamList } from "../types";

export type SideBarParamList = Pick<
  ScreenParamList,
  "Help" | "Home" | "LoggingOut" | "Merits" | "Opportunities" | "Settings" | "SharedLinks"
> & {
  readonly Icons: undefined;
};

const SideBar = createSideBarNavigator<SideBarParamList>();

export const SideBarNavigator = () => {
  const { data: accountMerit } = useAccountMerit();
  const { user } = useMeritAuth0();
  const logout = useLogout();
  const featureFlags = useFeatureFlags();
  const { _ } = useLingui();

  const fullName =
    accountMerit?.fields === undefined
      ? user?.nickname
      : getChosenNameFromContainerFields(accountMerit.fields);

  return (
    <SideBar.Navigator
      buttons={[
        {
          icon: <Icon name="helpOutlinedMediumDefault" />,
          id: "help",
          onPress: () => {
            openExternalLink(Constants.URLs.HELP);
          },
          order: "before",
          section: "bottom",
          text: _(msg({ context: "navigation item", message: "Help" })),
        },
        {
          icon: <Icon name="logoutMediumDefault" />,
          id: "logout",
          onPress: logout,
          order: "after",
          section: "bottom",
          text: _(msg({ context: "action", message: "Log Out" })),
        },
      ]}
      headerHeight={Constants.Sizes.HEADER_HEIGHT}
      headerIcon={<Icon name="avatarCircleMedium" />}
      headerText={fullName ?? ""}
      initialRouteName="Home"
    >
      <SideBar.Screen
        component={Home}
        name="Home"
        options={{
          icon: <Icon name="homeMediumDefault" />,
          title: _(msg({ context: "navigation item", message: "Home" })),
        }}
      />
      <SideBar.Screen
        component={MeritsScreen}
        name="Merits"
        options={{
          icon: <Icon name="meritOutlinedMedium" />,
          title: _(msg({ context: "navigation item", message: "Merits" })),
        }}
      />
      {featureFlags.showMemberAppOpportunitiesFeature === true && (
        <SideBar.Screen
          component={OpportunitiesScreen}
          name="Opportunities"
          options={{
            icon: <Icon name="verifiedMeritMediumDefault1" />,
            title: _(msg({ context: "navigation item", message: "Opportunities" })),
          }}
        />
      )}
      <SideBar.Screen
        component={SharedLinksScreen}
        name="SharedLinks"
        options={{
          icon: <Icon name="sendMediumDefault" />,
          section: "bottom",
          title: _(msg({ context: "navigation item", message: "Shared Links" })),
        }}
      />
      <SideBar.Screen
        component={Settings}
        name="Settings"
        options={{
          icon: <Icon name="gearOutlinedMedium" />,
          section: "bottom",
          title: _(msg({ context: "navigation item", message: "Settings" })),
        }}
      />
    </SideBar.Navigator>
  );
};

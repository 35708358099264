import { Log } from "@src/utils";
import { msg } from "@lingui/macro";
import { useAlerts, useLogoutOnError, useMeritAuth0 } from "@src/hooks";
import { useEffect } from "react";
import { useLingui } from "@lingui/react";
import { useMFAApi } from "./useMFAApi";
import { useQuery } from "@tanstack/react-query";
import type { MeritUserInfo } from "@src/types/user";

type QueryKey = readonly ["mfaEnrollments", { readonly entityID?: MeritUserInfo["entityID"] }];

export const useMFAEnrollmentsQueryKey = (): QueryKey => {
  const { user } = useMeritAuth0();

  return ["mfaEnrollments", { entityID: user?.entityID }];
};

export const useGetMFAEnrollments = () => {
  const { api: mfaApi, isReady: isMfaReady } = useMFAApi();
  const { sendAlert } = useAlerts();
  const { _ } = useLingui();
  const { onError } = useLogoutOnError();
  const queryKey = useMFAEnrollmentsQueryKey();

  const result = useQuery({
    enabled: isMfaReady,
    queryFn: () => mfaApi.getMFAEnrollments(),
    queryKey,
  });

  useEffect(() => {
    if (result.isError) {
      Log.error(`Error getting MFA enrollments`, { error: result.error });
      sendAlert({
        id: "getMFAEnrollments-error",
        text1: _(msg`Error getting MFA settings`),
        text2: String(result.error),
        type: "error",
      });
      onError(result.error);
    }
  }, [_, onError, result.isError, result.error, sendAlert]);

  return result;
};

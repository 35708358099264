import { Helpers } from "@merit/frontend-utils";
import { Log } from "@src/utils";
import { msg } from "@lingui/macro";
import { useAlerts } from "@src/hooks";
import { useGetSharesQueryKey } from "./useGetShares";
import { useLingui } from "@lingui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useShareMeritsApi } from "./useShareMeritsApi";
import type { SharePagesResponse, SharesApiDeleteShareRequest } from "@merit/share-merits-client";

const { None, Some } = Helpers;

export const useDeleteSharedLink = () => {
  const { api } = useShareMeritsApi();
  const { deleteAlert, sendAlert } = useAlerts();
  const { _ } = useLingui();
  const getSharesQueryKey = useGetSharesQueryKey();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (shareID: SharesApiDeleteShareRequest["shareID"]) => api.deleteShare({ shareID }),
    onError: (err, shareID, context) => {
      Log.error(`Error deleting shared links`, { error: err, shareID });

      if (Some(context?.previousRecords)) {
        queryClient.setQueryData(getSharesQueryKey, context.previousRecords);
      }

      deleteAlert("deleteSharedLink-deleting");
      sendAlert({
        id: "deleteSharedLink-error",
        text1: _(msg`Something went wrong`),
        text2: _(msg`Error deleting shared link`),
        type: "error",
      });
    },
    onMutate: async (shareID: string) => {
      sendAlert({
        id: "deleteSharedLink-deleting",
        text1: _(msg`Deleting…`),
        type: "info",
      });

      await queryClient.cancelQueries({ queryKey: getSharesQueryKey });

      const previousRecords = queryClient.getQueryData<{
        readonly pages: readonly SharePagesResponse[];
      }>(getSharesQueryKey);

      if (None(previousRecords)) {
        return undefined;
      }

      const updatedPages = previousRecords.pages.map(page => ({
        ...page,
        shares: page.shares?.filter(share => share.shareID !== shareID),
      }));

      const filteredPages = updatedPages.filter(page => (page.shares ?? []).length > 0);

      queryClient.setQueryData(getSharesQueryKey, {
        ...previousRecords,
        pages: filteredPages.length > 0 ? filteredPages : [{ hasMore: false, shares: [] }],
      });

      return { previousRecords };
    },
    onSuccess: () => {
      deleteAlert("deleteSharedLink-deleting");
      sendAlert({
        id: "deleteSharedLink-success",
        text1: _(msg`Success`),
        text2: _(msg`Your shared link has been deleted successfully`),
        type: "success",
      });
    },
  });
};

// Copyright 2025 Merit International Inc. All Rights Reserved

import { BlurView } from "expo-blur";
import { Body, Heading, Icon, useTheme } from "@merit/frontend-components";
import { Linking, Pressable, StyleSheet, View } from "react-native";
import { Trans } from "@lingui/macro";
import { VerticalSpacer } from "../../../../components/Spacer/VerticalSpacer";
import { useCallback } from "react";
import type { JobPosting } from "../../types";
import type { TextStyle, ViewStyle } from "react-native";

type Props = {
  readonly jobPosting: JobPosting;
};

const OpportunitiesListItem = ({ jobPosting: opportunity }: Props) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly badge: TextStyle;
    readonly badges: ViewStyle;
    readonly blurContainer: ViewStyle;
    readonly container: ViewStyle;
    readonly jobCard: ViewStyle;
    readonly rowSpaceBetween: ViewStyle;
    readonly rowFlexStart: ViewStyle;
    readonly link: TextStyle;
    readonly subduedColor: TextStyle;
  }>({
    badge: {
      backgroundColor: theme.colors.background.default,
      borderRadius: theme.spacing.xs,
      fontWeight: theme.fontWeights.semiBold,
      padding: theme.spacing.s,
    },
    badges: {
      flexDirection: "row",
      flexWrap: "wrap",
      gap: theme.spacing.s,
      marginVertical: theme.spacing.m,
    },
    blurContainer: {
      backgroundColor: "rgba(255, 255, 255, 0.3)",
      borderRadius: theme.spacing.s,
      bottom: 0,
      filter: "blur(10px)",
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
      zIndex: 1,
    },
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      flexDirection: "row",
      justifyContent: "space-between",
      marginHorizontal: theme.spacing.m,
      marginVertical: theme.spacing.m,
      position: "relative",
    },
    jobCard: {
      borderColor: theme.colors.border.subdued,
      borderRadius: theme.spacing.s,
      borderWidth: 1,
      flex: 1,
      overflow: "hidden",
      position: "relative",
    },
    link: {
      color: theme.colors.border.highlight.default,
    },
    rowFlexStart: {
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    rowSpaceBetween: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    subduedColor: {
      color: theme.colors.text.subdued,
    },
  });

  const onPress = useCallback(() => {
    Linking.openURL(opportunity.postingURL);
  }, [opportunity.postingURL]);

  const showRating = opportunity.rating !== undefined && !Number.isNaN(opportunity.rating);
  const showActiveDays =
    opportunity.activeDays !== undefined && !Number.isNaN(opportunity.activeDays);

  return (
    <View style={styles.container}>
      {(opportunity.isMeritPending ?? false) && (
        <BlurView
          experimentalBlurMethod="dimezisBlurView"
          intensity={10}
          style={styles.blurContainer}
          tint="light"
        />
      )}

      <View style={styles.jobCard}>
        <View style={{ padding: theme.spacing.l }}>
          <View style={styles.rowSpaceBetween}>
            <Heading bold level="3">
              {opportunity.title}
            </Heading>
            <Pressable>
              <Icon name="menuVerticalMediumDefault" />
            </Pressable>
          </View>
          <VerticalSpacer size={theme.spacing.s} />
          <View style={styles.rowFlexStart}>
            <Body style={{ paddingRight: theme.spacing.xs }}>{opportunity.company}</Body>
            {showRating && (
              <>
                <Body>{opportunity.rating.toFixed(1)}</Body>
                <Icon name="starMediumDefault" />
              </>
            )}
          </View>
          <Body>{opportunity.location}</Body>
          <View style={styles.badges}>
            <Body style={styles.badge}>{opportunity.salary}</Body>
            <Body style={styles.badge}>{opportunity.shiftAndSchedule}</Body>
          </View>

          <View style={styles.rowFlexStart}>
            <Icon name="chevronRightMediumAction" />
            <Body>
              <Trans>Easily apply</Trans>
            </Body>
          </View>
          <VerticalSpacer size={theme.spacing.s} />
          {opportunity.description.split(",").map(line => (
            <>
              <Body key={line} numberOfLines={1} style={styles.subduedColor}>
                • {line}
              </Body>
              <VerticalSpacer size={theme.spacing.xs} />
            </>
          ))}

          <Pressable onPress={onPress}>
            <Body style={styles.link}>
              <Trans>More…</Trans>
            </Body>
          </Pressable>
          {showActiveDays && (
            <>
              <VerticalSpacer size={theme.spacing.s} />
              <Body
                style={{ color: theme.colors.text.subdued, fontSize: theme.fontSizes.s.fontSize }}
              >
                <Trans> Active {opportunity.activeDays} days ago </Trans>
              </Body>
            </>
          )}
        </View>
      </View>
    </View>
  );
};

export { OpportunitiesListItem };

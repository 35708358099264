import { Header, Icon } from "@src/components";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useNavigation } from "@src/hooks";
import { useTheme } from "@merit/frontend-components";

export const OpportunitiesHeader = () => {
  const navigation = useNavigation();
  const { theme } = useTheme();

  const { _ } = useLingui();

  const handleLeftOnPress = () => {
    navigation.toggleDrawer();
  };

  return (
    <Header
      leftElement={<Icon name="menu_left" size={theme.fontSizes.xl.fontSize} />}
      leftOnPress={handleLeftOnPress}
      title={_(msg`Opportunities`)}
    />
  );
};

// Copyright 2025 Merit International Inc. All Rights Reserved

import { OpportunitiesScreen as DesktopOpportunitiesScreen } from "./OpportunitiesScreen.desktop";
import { OpportunitiesScreen as MobileOpportunitiesScreen } from "./OpportunitiesScreen.mobile";
import { useLayoutType } from "@src/hooks";

export const OpportunitiesScreen = () => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <DesktopOpportunitiesScreen />;
  }

  return <MobileOpportunitiesScreen />;
};

import { FlatList } from "react-native-gesture-handler";
import { OpportunitiesListItem } from "../OpportunitiesListItem";
import { mockJobPostings } from "../../OpportunitiesMockData";

export const OpportunitiesList = () => (
  <FlatList
    data={mockJobPostings}
    renderItem={({ item }) => <OpportunitiesListItem jobPosting={item} />}
  />
);
